import {
  ActionIcon,
  Box,
  Card,
  Center,
  Checkbox,
  Divider,
  Group,
  NumberInput,
  Radio,
  rem,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { Add, Trash } from 'iconsax-react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { POCLimitFormSchemaType } from './POCLimitPlayground'

export const POCForm = () => {
  const { register, control } = useFormContext<POCLimitFormSchemaType>()

  const coverageType = useWatch({ control, name: 'coverageType' })

  const {
    fields: items,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: 'items',
  })

  return (
    <Stack gap={0}>
      <Title order={3}>Proof Of Coverage Playground</Title>
      <Stack mt={8}>
        <Text fw={700} td="underline" mb={-8}>
          Claim Details
        </Text>
        <Stack mb={12}>
          <Controller
            control={control}
            name="coverageType"
            render={({ field }) => (
              <Radio.Group {...field} name="coverageType" label="Coverage Type">
                <Group mt="xs">
                  <Radio value="prescription" label="Prescription" />
                  <Radio value="labwork" label="Labwork" />
                  <Radio value="consultation" label="Consultation" />
                  <Radio value="other" label="Other" />
                </Group>
              </Radio.Group>
            )}
          />
        </Stack>
        <Group align="flex-end">
          <Controller
            control={control}
            name="percentage"
            render={({ field }) => (
              <NumberInput
                {...field}
                max={100}
                min={0}
                step={10}
                label="Percentage"
                styles={{
                  input: {
                    width: rem(80),
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="globalLimit"
            render={({ field }) => (
              <NumberInput {...field} w={120} min={0} step={10} thousandSeparator=" " label="Global Limit" />
            )}
          />
          {coverageType !== 'other' && (
            <>
              <Controller
                control={control}
                name="annualLimit"
                render={({ field }) => (
                  <NumberInput {...field} w={120} min={0} step={10} thousandSeparator=" " label="Annual Limit" />
                )}
              />
              <Controller
                control={control}
                name="transactionLimit"
                render={({ field }) => (
                  <NumberInput {...field} w={120} min={0} step={10} thousandSeparator=" " label="Transaction Limit" />
                )}
              />
            </>
          )}
          <Checkbox pos="relative" bottom={12} label="Cash Advance" {...register('cashAdvance')} />{' '}
        </Group>
      </Stack>
      <Stack mt={24}>
        <Text fw={700} td="underline">
          Items
        </Text>
        <SimpleGrid cols={2}>
          {items.map((item, index) => {
            return (
              <Card withBorder pos="relative" key={item.id}>
                <Box className="absolute top-1 right-1">
                  <ActionIcon color="alerts-red" variant="subtle" radius="xl" onClick={() => removeItem(index)}>
                    <Trash size={16} />
                  </ActionIcon>
                </Box>
                <SimpleGrid cols={2} verticalSpacing={6} spacing={24}>
                  <TextInput label="Name" {...register(`items.${index}.name`)} />
                  <TextInput label="Unit Name" placeholder="Optional" {...register(`items.${index}.unitName`)} />
                  <Controller
                    control={control}
                    name={`items.${index}.quantity`}
                    render={({ field }) => <NumberInput min={1} label="Quantity" thousandSeparator=" " {...field} />}
                  />
                  <Controller
                    control={control}
                    name={`items.${index}.pricePerUnit`}
                    render={({ field }) => (
                      <NumberInput min={0} label="Price / Unit" thousandSeparator=" " {...field} />
                    )}
                  />
                </SimpleGrid>
                <Divider variant="dashed" my={12} />
                <SimpleGrid cols={2} verticalSpacing={6} spacing={24}>
                  <Controller
                    control={control}
                    name={`items.${index}.limitPerUnit`}
                    render={({ field }) => (
                      <NumberInput min={0} label="Limit / Unit" thousandSeparator=" " {...field} />
                    )}
                  />
                  {coverageType === 'other' && (
                    <>
                      <Controller
                        control={control}
                        name={`items.${index}.annualLimit`}
                        render={({ field }) => (
                          <NumberInput min={0} label="Annual Limit" thousandSeparator=" " {...field} />
                        )}
                      />
                      <Controller
                        control={control}
                        name={`items.${index}.limitPerTransaction`}
                        render={({ field }) => (
                          <NumberInput min={0} label="Limit / Transaction" thousandSeparator=" " {...field} />
                        )}
                      />
                    </>
                  )}

                  <Checkbox
                    label="Excluded"
                    styles={{
                      root: {
                        position: 'relative',
                        bottom: rem(12),
                        alignSelf: 'end',
                      },
                    }}
                    {...register(`items.${index}.excluded`)}
                  />
                </SimpleGrid>
              </Card>
            )
          })}
          <Card
            withBorder
            styles={{
              root: {
                backgroundColor: 'var(--mantine-colors-gray-0)',
                borderStyle: 'dashed',
                borderWidth: rem(2),
              },
            }}>
            <Center flex={1}>
              <ActionIcon
                size={64}
                variant="subtle"
                onClick={() =>
                  appendItem({
                    name: `Item ${items.length + 1}`,
                    unitName: '',
                    quantity: 1,
                    pricePerUnit: 0,
                    excluded: false,
                  })
                }>
                <Add size={64} />
              </ActionIcon>
            </Center>
          </Card>
        </SimpleGrid>
      </Stack>
    </Stack>
  )
}
